@font-face {
    font-family: "Harlow";   /*Can be any text*/
    src: local("Harlow"),
        url("../fonts/Harlow.ttf") format("truetype");
}
@font-face {
    font-family: "Bellerose";   /*Can be any text*/
    src: local("Bellerose"),
        url("../fonts/Bellerose.ttf") format("truetype");
}

.title {
    font-family: Harlow;
    text-shadow: 2px 1px 1px black;
    font-size: larger;
}
.subtitle {
    font-family: Bellerose;
    /* text-shadow: 2px 1px 1px black; */
}
img {
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-filter: drop-shadow(2px 1px 1px black);
    filter: drop-shadow(2px 1px 1px black);
}
body {
    background: rgb(236,4,140);
    background: -moz-linear-gradient(90deg, #ec048c 0%, rgba(23,179,240,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(236,4,140,1) 0%, rgba(23,179,240,1) 100%);
    background: linear-gradient(90deg, rgba(236,4,140,1) 0%, rgba(23,179,240,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ec048c",endColorstr="#17b3f0",GradientType=1);
}
div.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    border-radius: 0px 0px 20px 20px;
    filter: drop-shadow(2px 1px 1px black);
}
div.mainContent {
    width: 100%;
    background-color: white;
    border-radius: 20px 20px 0px 0px;
    filter: drop-shadow(2px 1px 1px black);
    position: absolute;
    margin-top: 80vh;
    left: 0;
}
div.gallery {
    width: 100%;
    background-color: white;
    border-radius: 20px 20px 0px 0px;
    filter: drop-shadow(2px 1px 1px black);
    position: absolute;
    left: 0;
}
.headerButton {
    color: pink;
}
canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}